<template>
  <q-pull-to-refresh @refresh="refresh" class="pullRefresh">
    <div class="approvalOfPurchaseRequests">
      <DataCard
        :data="allFilters"
        :fields="fields"
        :actions="actions"
        @approve="approveRequest"
        @reject="rejectRequest"
        @cardClick="detailRequest"
        @filterR="filterBySupplierName"
        @filterB="filterByBranchCode"
        filterData
      />
    </div>
  </q-pull-to-refresh>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataCard from "../../components/DataCard";
import formatString from "@/helpers/formatString";

export default {
  components: {
    DataCard
  },
  data() {
    return {
      fields: [
        {
          field: "requestNumber",
          label: "",
          type: "string",
          class: "text-bold"
        },
        { field: "", label: "", type: "separator" },
        {
          field: "branch",
          label: "",
          type: "string",
          class: "text-body2"
        },
        /*
        {
          field: "amount",
          label: "",
          type: "currency",
          class: "text-overline"
        }
        */
        {
          field: "reqAndIssue",
          label: "",
          type: "string",
          class: "text-overline"
        }
      ],
      actions: [
        {
          label: "Aprovar",
          color: "green",
          icon: "check",
          eventEmit: "approve"
        },
        { label: "Rejeitar", color: "red", icon: "block", eventEmit: "reject" }
      ],
      filterWebR: null,
      filterWebB: null,
      filterR: sessionStorage.filterWebR,
      filterB: sessionStorage.filterWebB
    };
  },
  computed: {
    ...mapState("purchaseRequests", ["requests"]),

    allFilters() {
      let requests = this.requests;

      switch (sessionStorage.sortWeb) {
        case "sSmLg":
          requests.sort((a, b) => a.amountOfRequest - b.amountOfRequest);
          break;
        case "sLgSm":
          requests.sort((a, b) => b.amountOfRequest - a.amountOfRequest);
          break;
        case "sNewOld":
          requests.sort((a, b) => b.issueDate - a.issueDate);
          break;
        case "sOldNew":
          requests.sort((a, b) => a.issueDate - b.issueDate);
          break;
      }

      if (this.filterR) {
        requests = requests.filter(req => {
          return req.requestNumber.trim().indexOf(this.filterR.trim()) >= 0;
        });
      }

      if (this.filterB) {
        if (sessionStorage.filterWebB) {
          requests = requests.filter(req => {
            return (
              req.branchCode.trim().indexOf(sessionStorage.filterWebB.trim()) >=
              0
            );
          });
        } else {
          requests = requests.filter(req => {
            return req.branchCode.trim().indexOf(this.filterB.trim()) >= 0;
          });
        }
      }

      return requests.map(req => {
        return {
          id: req.id,
          branch: `${req.branchCode} - ${req.branchName}`,
          reqAndIssue: `${formatString(
            req.issueDate,
            "date"
          )} - ${req.requester.toUpperCase()}`,
          requester: req.requester.toUpperCase(),
          issueDate: req.issueDate,
          requestNumber: req.requestNumber
        };
      });
    }
  },
  methods: {
    ...mapActions("purchaseRequests", [
      "getRequests",
      "approvesRejectsPurchaseRequest"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    approveRequest(request) {
      this.sendResponse(request, "approved", "APROVAR", "text-green");
    },
    rejectRequest(request) {
      this.sendResponse(request, "rejected", "REJEITAR", "text-red");
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Pedido ${request.requestNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          try {
            await this.approvesRejectsPurchaseRequest({ request, response });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.getRequests();
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    detailRequest(requestId) {
      this.$router.push({
        name: "requestDetail",
        params: { requestId }
      });
    },
    filterBySupplierName(filterR) {
      this.filterR = filterR;
    },
    filterByBranchCode(filterB) {
      if (sessionStorage.filterWebB && !filterB) {
        this.filterB = sessionStorage.filterWebB;
      } else {
        this.filterB = filterB;
        sessionStorage.setItem("filterWebB", filterB);
      }
    },
    async refresh(done) {
      await this.getRequests();
      done();
    }
  },
  created() {
    this.getRequests();
  }
};
</script>
<style lang="scss">
.approvalOfPurchaseRequests {
  width: 100%;
}
</style>
